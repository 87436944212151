@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@art-reviews-widget-id: ~'@{ra-prefix}-art-reviews-widget';

:export {
  artReviewsWidgetId: @art-reviews-widget-id;
}

#@{art-reviews-widget-id} {
  width: 100%;
  margin: 0 auto;
}
